.navbar {
  padding: 15px 0 !important;
  position: relative;

  .active-link.active {
    color: #24bcaa !important;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-color: #24bcaa;
      width: 30px;
      height: 1.5px;
      top: 33px;
    }
  }
  .navbar-nav a {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    color: #131d24 !important;
    margin-left: 50px;
  }
}
.navbar-nav {
  position: relative;
}
.navdesc {
  height: 7rem;
  @include truncate();
  overflow: hidden;
  margin-bottom: 0 !important;
}
.navBarContainer .navBar {
  position: relative !important;
}

.navbarDropdown {
  background-color: #2e4859;
  padding: 28px 31px;
  position: absolute;
  z-index: 99999;
  width: 80%;
  &__heading {
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.02em;
    color: color(primary, base);
    border-bottom: 0.5px solid #e0e0e0;
    padding-bottom: 10px;
  }
  ul {
    padding-left: 0;
    li {
      padding-bottom: 8px;
      a {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #ffffff !important;
      }
    }
  }
  &__arrowup {
    position: absolute;
    right: 35%;
    width: 20px;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 15px solid #2e4859;
    top: -6%;
  }
}
.resortstoggle {
  position: absolute;
  border: $border;
  left: 40%;
  top: 100%;
  z-index: 999;
  width: 60%;
  background-color: #f3f3f3;
  &__leftnav {
    background-color: #fff;
    height: 410px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px; /* Width of the scrollbar */
      margin: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1; /* Color of the track */
      border-radius: 10px; /* Rounded corners */
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      padding: 5px;
      border-radius: 10px; /* Rounded corners */
      background-color: #888;
    }

    ul {
      padding-left: 0;

      li {
        padding: 12px 0 12px 25px;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        &:hover {
          color: white !important;
          background: linear-gradient(
            54.88deg,
            #856402 -15.56%,
            #e0a700 46.22%,
            #856402 103.33%
          );

          a {
            color: white !important;
          }
        }
      }
    }
  }
  &__rightnav {
    padding-top: 20px;
    padding-right: 25px;
    img {
      height: 200px;
      object-fit: cover;
      width: 100%;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #0d0d0d;
      margin-top: 18px;
      margin-bottom: 20px;
    }
  }
}
.navBar-menu__items {
  color: #000;
}
.smallnavbar {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  &__image {
    img {
      width: 22%;
    }
  }
  &__openicon {
    margin-right: 20px;
    font-size: 28px;
    color: color(primary, base);
    border: 2px solid color(primary, base);
    padding: 4px;
    border-radius: 20%;
  }
  &__display {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 99999;
    img {
      position: absolute;
      left: 20px;
      top: 28px;
      width: 25%;
    }
    &--crossicon {
      position: absolute;
      right: 30px;
      top: 40px;
      color: color(primary, base);
      border: 2px solid color(primary, base);
      padding: 5px;
      border-radius: 50%;
      font-size: 25px;
    }
    &--mainul {
      margin-top: 100px;
      border-top: 2px solid color(primary, base);
      padding-top: 10px;
      li {
        padding: 10px 0;
      }
    }
    &--mainul-subul {
      li {
        padding: 5px 0;
        position: relative;
        a {
          height: 1px;
          color: #000 !important;
          &::after {
            content: '';
            background-color: color(primary, base);
            height: 2px;
            width: 35px;
            position: absolute;
            top: 25px;
            left: 0;
          }
        }
      }
    }
  }
}
// @media (min-width: 768px) {
//   .smallnavbar {
//     display: none !important;
//   }
// }
// @media (max-width: 768px) {
//   .largenavbar {
//     display: none !important;
//   }
// }

@include responsive-for(md) {
  .smallnavbar {
    display: flex !important;
  }
}
@include responsive-for(md) {
  .largenavbar {
    display: none !important;
  }
}
@media (max-width: 425px) {
  .smallnavbar__image img {
    width: 32%;
  }
}
